import Api from "../../lib/api";

export const restaurants = {
  state: {
    nearRestaurants: [],
    suggestedRestaurants: [],
    singleRestaurants: {},
  },
  reducers: {
    setNearRestaurants(state, payload) {
      return {
        ...state,
        nearRestaurants: payload,
      };
    },
    setSuggestedRestaurants(state, payload) {
      return {
        ...state,
        suggestedRestaurants: payload,
      };
    },
    setSingleRestaurants(state, payload) {
      return {
        ...state,
        singleRestaurants: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async fetchNearRestaurants(payload) {
      let dd = (payload = {
        latitude: "151.5072",
        longitude: "0.1276",
      });
      try {
        const data = Api.post(
          "/?action=get_popular_restaurant",
          payload ? payload : dd
        )
          .then(async function (response) {
            //const res = await mapper(response.data);
            const res = response.data;
            if (res.status !== 0) {
              await dispatch.restaurants.setNearRestaurants(res.data);
            } else {
              await dispatch.restaurants.setNearRestaurants([]);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
    async fetchSuggestedRestaurants(payload) {
      try {
        const data = Api.post("/?action=get_new_restaurant", payload)
          .then(async function (response) {
            // Parse  JSON object individually
            //const res = await mapper(response.data);
            const res = response.data;
            if (res.status !== 0) {
              await dispatch.restaurants.setSuggestedRestaurants(res.data);
            } else {
              await dispatch.restaurants.setSuggestedRestaurants([]);
            }
          })
          .catch(function (error) {
          });
        return data;
      } catch (error) {}
    },

    async fetchSingleRestaurant(payload) {
      try {
        const data = Api.get(`/?action=get_restaurant_item&item_id=${payload}`)
          .then(async function (response) {
            const res = response.data;
            if (res.status !== 0) {
              await dispatch.restaurants.setSingleRestaurants(res.data);
            } else {
              await dispatch.restaurants.setSingleRestaurants([]);
            }
          })
          .catch(function (error) {
          });
        return data;
      } catch (error) {}
    },
  }),
};

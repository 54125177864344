import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, Container, Typography} from "@mui/material";
import DashboardNav from "../../layouts/dashboard/DashboardNav";
import NoItemsFound from "../../layouts/dashboard/NoItemsFound";
import ChirzLoader from "../../components/UI/ChirzLoader";
import FavouriteItem from "./FavouriteItem";
import {Link} from "react-router-dom";
import store from "../../redux/store";

const Favourites = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.users.user);
  const initialFavourite = useSelector((state) => state.favourite.favourites);
  const [favouriteItems, setFavouriteItems] = useState(initialFavourite);
  let favouritesContent;
  const favouriteHandler = async () => {
    let currentLoggedUser = new FormData();
    currentLoggedUser.append("user_id", loggedInUser.u_id);
    const favouriteList = await dispatch.favourite.fetchFavourites(
      currentLoggedUser
    );
    let currentFavouriteItems = store.getState().favourite.favourites;
    if (currentFavouriteItems !== undefined) {
      setFavouriteItems(currentFavouriteItems);
    }
    return favouriteList;
  };
  useEffect(() => {
    favouriteHandler();
  }, {});
  if (favouriteItems !== undefined) {
    if (favouriteItems.length > 0) {
      favouritesContent = (
        <Container>
          <Box display="flex" flexDirection="column" gap="24px" my="28px">
            {favouriteItems.map((favouriteItem, index) => (
              <FavouriteItem
                title={favouriteItem.item_name}
                subTitle={favouriteItem.item_type}
                year={favouriteItem.year}
                id={favouriteItem.item_id}
                key={index}
              />
            ))}
          </Box>
        </Container>
      );
    } else {
      favouritesContent = (
        <Container
          sx={{
            minHeight: "calc(100vh - 65px)",
            maxWidth: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NoItemsFound title="No favourites yet">
            Tap the star icon near any restaurant and we’ll save it here for
            you.
          </NoItemsFound>
        </Container>
      );
    }
  } else {
    return <ChirzLoader />;
  }
  let favouritesPage;
  if (loggedInUser !== null) {
    favouritesPage = (
      <>
        <DashboardNav title="Favourites" border={true} />
        {favouritesContent}
      </>
    );
  } else {
    favouritesPage = (
      <Box
        width="100vw"
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="16px"
      >
        <Typography variant="large">Login first to access page!</Typography>
        <Link to="/login" className="linkButton">
          Log in
        </Link>
      </Box>
    );
  }
  return favouritesPage;
};
export default Favourites;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Avatar,
  Box,
  Container,
  Drawer,
  IconButton,
  List,
  ListItemText,
  Toolbar,
  Typography,
  Divider,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Alert,
  Snackbar,
} from "@mui/material";
import { ChirzBtn } from "../../../components/UI/ChirzButtons";

import BackIcon from "../../../assets/images/BackIcon.svg";
import Help from "../../../assets/images/Help.svg";
import Star from "../../../assets/images/Star.svg";
import Restaurant from "../../../assets/images/Cocktail.svg";
import Privacy from "../../../assets/images/Privacy.svg";
import TCs from "../../../assets/images/TCs.svg";
import { useDispatch } from "react-redux";

const dashboardMenu = [
  { name: "Restaurants", icon: Restaurant, link: "/" },
  { name: "Favourites", icon: Star , link: "/favourites"},
  { name: "Privacy", icon: Privacy , link: "https://www.chirz.co.uk/privacy"},
  { name: "Help", icon: Help, link: "/help" },
  { name: "T&Cs", icon: TCs, link: "/t&cs" },
];
const Sidebar = (props) => {
  const user = props.user;
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const [tabValue, setTabValue] = useState("Restaurants");
  const [deleteAccount, setDeleteAccount] = useState(false);

  const logoutHandler = () => {
    dispatch.users.fetchLogout();
  };
  const changeHandler = (e, val) => {
    setTabValue(val);
  };
  const closeSidebar = () => {
    props.onSidebarNav(false);
  };
  function stringAvatar(name) {
    if (name) {
      const firstName = name.split(" ")[0];
      if (name.length > firstName.length) {
        return {
          children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
        };
      } else {
        return {
          children: `${name.split(" ")[0][0]}`,
        };
      }
    }
  }
  const deleteHandler = () => {
    setDeleteAccount(true);
  };
  const deleteCloseHandler = () => {
    setDeleteAccount(false);
  };
  const deleteAccountFetch = async () => {
    await dispatch.users.fetchDeleteAccount({ user_id: user.u_id });
  };
  const deleteAccountHandler = () => {
    deleteAccountFetch();
    setAlert(true);
    setDeleteAccount(false);
    props.onSidebarNav(false);
  };
  const alertHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };
  return (
    <>
      <Drawer anchor="left" open={props.sidebar} onClose={closeSidebar}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton onClick={closeSidebar} aria-label="pervious">
              <img src={BackIcon} width={12} height={12} alt="previous" />
            </IconButton>
            <Typography
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              variant="large"
            >
              Profile
            </Typography>
          </Toolbar>
          <Container sx={{ mb: "24px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "16px",
              }}
            >
              <Avatar
                sx={{
                  width: 64,
                  height: 64,
                  backgroundImage: `url(${user.profile_image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "contain",
                  fontSize: 24,
                  fontWeight: 700,
                }}
                {...stringAvatar(user.username)}
              />
              <List>
                <ListItemText>
                  <Typography variant="title" sx={{ fontWeight: 700 }}>
                    {user.username}
                  </Typography>
                </ListItemText>
                <ListItemText>
                  <Typography variant="regular">{user.email}</Typography>
                </ListItemText>
              </List>
            </Box>
            <ChirzBtn
              LinkComponent={Link}
              to={"/settings"}
              sx={{
                height: 32,
                ml: "80px",
                fontSize: 12,
              }}
            >
              Edit details
            </ChirzBtn>
          </Container>
          <Divider variant="middle" />
          <Tabs
            orientation="vertical"
            value={tabValue}
            onChange={changeHandler}
            aria-label="dashboard"
            sx={{ my: "16px" }}
            variant="fullWidth"
          >
            {dashboardMenu.map(({ name, icon,link }) => (
              <Tab
                value={name}
                key={name.toLocaleLowerCase()}
                icon={<img src={icon} width={24} height={24} alt={name} />}
                iconPosition="start"
                label={name}
                variant="chirz"
                LinkComponent={Link}
                to={link}
                onClick={closeSidebar}
              />
            ))}
          </Tabs>
          <Divider variant="middle" />
          <Tabs
            orientation="vertical"
            variant="fullWidth"
            value={tabValue}
            onChange={changeHandler}
            aria-label="dashboard"
            sx={{ mt: "16px" }}
          >
            <Tab
              value="Deleteacount"
              key="deleteacount"
              label="Delete Acount"
              variant="chirz"
              sx={{ justifyContent: "center", alignItems: "flex-start" }}
              onClick={deleteHandler}
            />
            <Tab
              value="Logout"
              key="logout"
              label="Log out"
              variant="chirz"
              sx={{ justifyContent: "center", alignItems: "flex-start" }}
              onClick={logoutHandler}
            />
          </Tabs>
        </Box>
      </Drawer>
      <Dialog open={deleteAccount} onClose={deleteCloseHandler}>
        <DialogContent>
          <Typography variant="regular">
            Are You Sure You Want to Delete your account?
          </Typography>
        </DialogContent>
        <DialogActions>
          <ChirzBtn
            sx={{
              height: 32,
              fontSize: 12,
            }}
            onClick={deleteCloseHandler}
          >
            No
          </ChirzBtn>
          <ChirzBtn
            sx={{
              height: 32,
              fontSize: 12,
            }}
            onClick={deleteAccountHandler}
          >
            Yes
          </ChirzBtn>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alert}
        autoHideDuration={1200}
        onClose={alertHandler}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={alertHandler} severity="success" sx={{ width: "100%" }}>
          You're Account Deleted Successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Sidebar;

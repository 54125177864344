import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import RestaurantFoodItem from "./RestaurantFoodItem";

import RestaurantDrinkItem from "./RestaurantDrinkItem";
import { useSelector } from "react-redux";
import ChirzLoader from "../../components/UI/ChirzLoader";

const RestaurantItems = (props) => {
  const foodList = useSelector((state) => state.food.foodList);
  const [foodInfoList, setFoodInfoList] = useState();

  const { type, region, grape, alcohol, year } = props.filterValue || {};

  useEffect(() => {
    const applyFilters = (foodItems) => {
      let filtered = foodItems;

      if (type) {
        filtered = filtered.map((item) => ({
          ...item,
          food: item.food?.filter((foodItem) => foodItem.item_type === type),
        }));
      }

      if (region) {
        filtered = filtered.map((item) => ({
          ...item,
          food: item.food?.filter((foodItem) => foodItem.region === region),
        }));
      }

      if (grape) {
        filtered = filtered.map((item) => ({
          ...item,
          food: item.food?.filter(
            (foodItem) => foodItem.type_of_grape === grape
          ),
        }));
      }

      if (alcohol) {
        filtered = filtered.map((item) => ({
          ...item,
          food: item.food?.filter((foodItem) => foodItem.alcohol === alcohol),
        }));
      }

      if (year) {
        filtered = filtered.map((item) => ({
          ...item,
          food: item.food?.filter((foodItem) => foodItem.year === year),
        }));
      }

      return filtered;
    };

    const foodItemsArr = foodList.filter(
      (food) => food.menu_name === props.value
    );
    const filteredItems = applyFilters(foodItemsArr);

    props.value === "Wine"
      ? setFoodInfoList(filteredItems[0])
      : setFoodInfoList(foodItemsArr[0]);
  }, [foodList, props.value, type, region, grape, alcohol, year]);

  return (
    <>
      <div role="tabpanel" hidden={props.value !== props.name}>
        {foodInfoList === undefined && <ChirzLoader />}
        {foodInfoList !== undefined && (
          <Box display="flex" flexDirection="column" gap="24px" pb="40px">
            {props.id !== "wine" &&
              props.id !== "cocktail" &&
              props.id !== "coldbrew" &&
              props.search === undefined &&
              foodInfoList.food.map((item) => (
                <RestaurantFoodItem
                  name={item.food_name}
                  img={item.image}
                  cover={item.image}
                  desc={item.description}
                  price={item.price}
                  key={item.pair_id}
                  matchingDrinks={item.matchingDrinks}
                  id={item.pair_id}
                  isEmSherif={props.isEmSherif}
                />
              ))}
            {props.id !== "wine" &&
              props.id !== "cocktail" &&
              props.id !== "coldbrew" &&
              props.search !== undefined && (
                <RestaurantFoodItem
                  name={props.search.food_name}
                  img={props.search.image}
                  cover={props.search.image}
                  desc={props.search.description}
                  price={props.search.price}
                  key={props.search.pair_id}
                  matchingDrinks={props.search.matchingDrinks}
                  id={props.search.pair_id}
                  isEmSherif={props.isEmSherif}
                />
              )}
            {props.id === "wine" &&
              props.search === undefined &&
              props.id !== "coldbrew" &&
              foodInfoList.food.map(
                (item) =>
                  item.is_cocktail === "0" && (
                    <RestaurantDrinkItem
                      id={item.id}
                      category="Wines"
                      img={item.image}
                      type={item.item_type}
                      name={item.food_name}
                      year={item.year}
                      desc={item.about_wine}
                      country={item.region}
                      region={item.region}
                      grape={item.type_of_grape}
                      alcohol={item.alcohol_percentage}
                      pricePerGlass={item.price_per_glass}
                      pricePerBottle={item.price_per_bott}
                      sweetness={item.sweetness}
                      acidity={item.acidity}
                      key={item.id}
                      stock={item.in_stock}
                      isEmSherif={props.isEmSherif}
                    />
                  )
              )}
            {props.id === "wine" &&
              props.search !== undefined &&
              props.id !== "coldbrew" &&
              props.search.is_cocktail === "0" && (
                <RestaurantDrinkItem
                  id={props.search.id}
                  category="Wines"
                  img={props.search.image}
                  type={props.search.item_type}
                  name={props.search.food_name}
                  year={props.search.year}
                  desc={props.search.about_wine}
                  country={props.search.region}
                  region={props.search.region}
                  grape={props.search.type_of_grape}
                  alcohol={props.search.alcohol_percentage}
                  pricePerGlass={props.search.price_per_glass}
                  pricePerBottle={props.search.price_per_bott}
                  sweetness={props.search.sweetness}
                  acidity={props.search.acidity}
                  key={props.search.id}
                  stock={props.search.in_stock}
                  isEmSherif={props.isEmSherif}
                />
              )}
            {props.id !== "wine" &&
              props.search === undefined &&
              props.id !== "coldbrew" &&
              foodInfoList.food.map(
                (item) =>
                  item.is_cocktail === "1" && (
                    <RestaurantDrinkItem
                      id={item.id}
                      category="Cocktails"
                      img={item.image}
                      name={item.food_name}
                      desc={item.about_wine}
                      country={item.region}
                      region={item.region}
                      grape={item.type_of_grape}
                      alcohol={item.alcohol_percentage}
                      pricePerGlass={item.price_per_cocktail}
                      pricePerBottle={item.price_per_bott}
                      sweetness={item.sweetness}
                      acidity={item.acidity}
                      key={item.id}
                      stock={item.in_stock}
                      isEmSherif={props.isEmSherif}
                    />
                  )
              )}
            {props.id !== "wine" &&
              props.search !== undefined &&
              props.id !== "coldbrew" &&
              props.search.is_cocktail === "1" && (
                <RestaurantDrinkItem
                  id={props.search.id}
                  category="Cocktails"
                  img={props.search.image}
                  name={props.search.food_name}
                  desc={props.search.about_wine}
                  country={props.search.region}
                  region={props.search.region}
                  grape={props.search.type_of_grape}
                  alcohol={props.search.alcohol_percentage}
                  pricePerGlass={props.search.price_per_cocktail}
                  pricePerBottle={props.search.price_per_bott}
                  sweetness={props.search.sweetness}
                  acidity={props.search.acidity}
                  key={props.search.id}
                  stock={props.search.in_stock}
                  isEmSherif={props.isEmSherif}
                />
              )}

            {props.id === "coldbrew" &&
              props.search === undefined &&
              foodInfoList.food.map((item) => (
                <RestaurantDrinkItem
                  id={item.id}
                  category="Cold Brew"
                  img={item.image}
                  name={item.food_name}
                  desc={item.about_wine}
                  country={item.region}
                  region={item.region}
                  grape={item.type_of_grape}
                  alcohol={item.alcohol_percentage}
                  pricePerGlass={item.price_per_cocktail}
                  pricePerBottle={item.price_per_bott}
                  sweetness={item.sweetness}
                  acidity={item.acidity}
                  key={item.id}
                  stock={item.in_stock}
                  isEmSherif={props.isEmSherif}
                />
              ))}

            {props.id === "coldbrew" && props.search !== undefined && (
              <RestaurantDrinkItem
                id={props.search.id}
                category="Cold Brew"
                img={props.search.image}
                name={props.search.food_name}
                desc={props.search.about_wine}
                country={props.search.region}
                region={props.search.region}
                grape={props.search.type_of_grape}
                alcohol={props.search.alcohol_percentage}
                pricePerGlass={props.search.price_per_cocktail}
                pricePerBottle={props.search.price_per_bott}
                sweetness={props.search.sweetness}
                acidity={props.search.acidity}
                key={props.search.id}
                stock={props.search.in_stock}
                isEmSherif={props.isEmSherif}
              />
            )}
          </Box>
        )}
      </div>
    </>
  );
};

export default RestaurantItems;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import store from "../../../redux/store";
import { useNavigate } from "react-router-dom";

import { Container, Typography, Box, Alert, Snackbar } from "@mui/material";
import DashboardNav from "../../../layouts/dashboard/DashboardNav";
import { ChirzInput } from "../../UI/ChirzInput";
import { ChirzBtn } from "../../UI/ChirzButtons";
import { ChirzLink } from "../../UI/ChirzLink";

import styles from "./Login.module.css";

const ForgotPassword = () => {
  // Define Basics.
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // State for set user email.
  const [email, setEmail] = useState("");
  // State for showing or hiding alert.
  const [alert, setAlert] = useState(false);
  // State for error alert details.
  const [alertDetails, setAlertDetails] = useState({
    color: "error",
    message: "",
  });
  //Handle Forgot Password Function
  const forgotPasswordHandler = async () => {
    // Turn current user informations to FormatDate.
    let currentUser = new FormData();
    currentUser.append("email", email);
    await dispatch.users.fetchForgotPassword(currentUser);
    // Extract forgot password message after fetchForgotPassword.
    let forgotPasswordError = store.getState().users.forgotPasswordError;
    let forgotPasswordAlert = store.getState().users.forgotPasswordAlert;
    // Show alert.
    setAlert(true);
    // Login or show errors
    if (forgotPasswordError !== undefined) {
      // Set error alert details
      setAlertDetails({
        color: "error",
        message: forgotPasswordError,
      });
    } else {
      // Set check mail alert details
      setAlertDetails({
        color: "success",
        message: forgotPasswordAlert,
      });
      setTimeout(() => {
        navigate("/login");
      }, 1800);
    }
  };
  // Create show or hide alert function
  const alertHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  return (
    <>
      <DashboardNav title="Forgot Password" />
      <Container
        sx={{ display: "flex", justifyContent: "center", minHeight: "80vh" }}
      >
        <form className={styles.login}>
          <Box display="flex" flexDirection="column" gap="16px">
            <ChirzInput
              label="email@example.com"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              inputProps={{ autoComplete: "off" }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="16px"
          >
            <ChirzBtn
              sx={{ textTransform: "uppercase", width: "100%" }}
              onClick={forgotPasswordHandler}
            >
              Set New Password
            </ChirzBtn>
          </Box>
        </form>
      </Container>
      <Snackbar
        open={alert}
        autoHideDuration={1500}
        onClose={alertHandler}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={alertHandler}
          severity={alertDetails.color}
          sx={{ width: "100%" }}
        >
          {alertDetails.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ForgotPassword;

/* eslint-disable import/no-unresolved */
import storage from "redux-persist/lib/storage";
import createRematchPersist from "@rematch/persist";
import { AllFilters } from "../filters";

export const persistPlugin = createRematchPersist({
  key: "root",
  whitelist: ["users", "restaurants", "drink", "food", "favourite", "rating","location"],
  blacklist: [],
  version: 1,
  storage,
  transforms: AllFilters,
});

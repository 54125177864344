import React, { useState } from "react";

import { Autocomplete, Box, Popper, styled, TextField } from "@mui/material";

import searchIcon from "../../assets/images/Search.svg";

const CustomPopper = styled(Popper)((props) => ({
  '& .MuiAutocomplete-listbox': {
    fontFamily: props.isEmSherif ? "Montserrat" : undefined,
  },
}));

const Search = (props) => {
  const [searchItem, setSearchItem] = useState("");
  props.searchKeyHandler(searchItem);

  const isEmSherif= props.isEmSherif;

  return (
    <>
      <Box display="flex" alignItems="center" px="3px">
        <img src={searchIcon} width={18} height={18} />
        <Autocomplete
          value={searchItem}
          onChange={(e, newValue) => setSearchItem(newValue)}
          variant="chirz"
          id="restaurants-search"
          options={props.item}
          renderInput={(params) => (
            <TextField {...params} placeholder="Search" sx={{
              '& .MuiInputBase-input': {
                fontFamily: isEmSherif ? "Montserrat" : undefined,
              },
            }}/>
          )}
          PopperComponent={(props) => <CustomPopper {...props} isEmSherif={isEmSherif}/>}
        />
      </Box>
    </>
  );
};

export default Search;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Theme } from "../../../components/UI/Theme";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Avatar,
  Container,
} from "@mui/material";
import Dashboard from "../../../layouts/dashboard/Dashboard";

import Person from "../../../assets/images/Person.svg";

const avatarStyle = {
  cursor: "pointer",
  height: "24px",
  "@media (min-width:600px)": {
    height: "40px",
  },
  img: {
    objectFit: "contain",
  },
};

const Navbar = () => {
  // Define Basics.
  const navigate = useNavigate();
  //Extract loggedin user from users state.
  const loggedInUser = useSelector((state) => state.users.user);
  // State for open and close sidebar.
  const [sidebar, setSidebar] = useState(false);

  // Create function for opening and closing sidebar
  const sidebarHandler = (situation) => {
    setSidebar(situation);
  };
  // Create function for opening sidebar or going to login page
  const sidebarOpen = () => {
    if (loggedInUser) {
      setSidebar(true);
      sidebarHandler(true);
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <AppBar sx={{ backgroundColor: Theme.palette.white, position: "sticky", }}>
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: 0,
            paddingRight: 0,
          }}
          width="100%"
        >
          <Container
            width="100%"
            maxWidth="xl"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="title" sx={{ color: "#181919" }}>
                Locations
              </Typography>
            </Box>
            <Box>
              <Avatar
                src={Person}
                sx={avatarStyle}
                alt={loggedInUser ? "Dashboard" : "Login"}
                onClick={sidebarOpen}
              />
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      {loggedInUser && (
        <Dashboard
          opened={sidebar}
          onSidebar={sidebarHandler}
          user={loggedInUser}
        />
      )}
    </>
  );
};
export default Navbar;

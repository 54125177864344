import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DashboardNav from "../../layouts/dashboard/DashboardNav";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { ChirzInput, ChirzReadOnly } from "../../components/UI/ChirzInput";
import { ChirzBtn } from "../../components/UI/ChirzButtons";
import ChirzModal from "../../components/UI/ChirzModal";

import styles from "./Settings.module.css";
import { Theme } from "../../components/UI/Theme";
import BackIcon from "../../assets/images/BackIcon.svg";
import { Link } from "react-router-dom";

const Settings = () => {
  // Define Basics.
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.users.user);
  // State for showing or hiding edit settings modal.
  const [settings, setSettings] = useState(false);
  // Extract loggedin user from sate
  const user = useSelector((state) => state.users.profileDetails);
  // State for edited user profile details
  const [editedName, setEditedName] = useState(user.username);
  const [editedEmail, setEditedEmail] = useState(user.email);

  // Closing food details based on situation from FoodModal
  const settingsCloseHandler = () => {
    setSettings(false);
  };
  // Create show or hide modal function
  const settingsHandler = () => {
    setSettings(true);
  };
  // Create save settings function
  const saveSettingsHandler = () => {
    // Turn current user informations to FormatDate.
    let currentUser = new FormData();
    currentUser.append("user_id", user.u_id);
    currentUser.append("email", editedEmail);
    currentUser.append("username", editedName);
    // Call fetchEditProfile function from users modal.
    dispatch.users.fetchEditProfile(currentUser);
    setSettings(false);
  };
  let settingsPage;
  if (loggedInUser !== null) {
    settingsPage = (
      <>
        <DashboardNav title="Edit details" border={true} />
        <Box display="flex" justifyContent="center" minHeight="80vh">
          <Box className={styles.settings}>
            <Box width="100%" display="flex" flexDirection="column">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                height={64}
                borderBottom="1px solid #F2F4F5"
                px="24px"
              >
                <Typography variant="regularTight">Name</Typography>
                <Typography variant="regularTight">{user.username}</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                height={64}
                borderBottom="1px solid #F2F4F5"
                px="24px"
              >
                <Typography variant="regularTight">Email</Typography>
                <Typography variant="regularTight">{user.email}</Typography>
              </Box>
            </Box>
            <Container>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                flexDirection="column"
                px="4px"
              >
                <ChirzBtn
                  sx={{ textTransform: "uppercase", width: "100%" }}
                  onClick={settingsHandler}
                >
                  Edit details
                </ChirzBtn>
              </Box>
            </Container>
          </Box>
        </Box>
        <ChirzModal
          open={settings}
          onClose={settingsCloseHandler}
          innerWidth="100vw"
        >
          <Box
            width="100%"
            pt="64px"
            pb="56px"
            px="24px"
            sx={{
              position: "relative",
              height: { xs: "100vh", sm: "80vh" },
              borderRadius: { xs: "0px", sm: "16px" },
              overflow: "hidden",
              backgroundColor: Theme.palette.offWhite,
              overflowY: "auto",
            }}
          >
            <AppBar position="absolute">
              <Toolbar
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
                width="100%"
              >
                <Container
                  width="100%"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    onClick={settingsCloseHandler}
                    aria-label="previous"
                  >
                    <img src={BackIcon} width={12} height={12} alt="previous" />
                  </IconButton>
                  <Typography
                    variant="large"
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Edit Settings
                  </Typography>
                </Container>
              </Toolbar>
            </AppBar>
            <Container sx={{ height: "100%" }}>
              <form className={styles["settings-form"]}>
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  gap="16px"
                >
                  <ChirzInput
                    label="Name"
                    variant="outlined"
                    type="text"
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                    inputProps={{ autoComplete: "off" }}
                  />
                  <ChirzInput
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={editedEmail}
                    onChange={(e) => setEditedEmail(e.target.value)}
                    inputProps={{ autoComplete: "off" }}
                  />
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap="16px"
                >
                  <ChirzBtn
                    sx={{ textTransform: "uppercase", width: "100%" }}
                    onClick={saveSettingsHandler}
                  >
                    Save Settings
                  </ChirzBtn>
                </Box>
              </form>
            </Container>
          </Box>
        </ChirzModal>
      </>
    );
  } else {
    settingsPage = (
      <Box
        width="100vw"
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="16px"
      >
        <Typography variant="large">Login first to access page!</Typography>
        <Link to="/login" className="linkButton">
          Log in
        </Link>
      </Box>
    );
  }
  return settingsPage;
};
export default Settings;

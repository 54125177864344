import Api from "../../lib/api";
export const favourite = {
  state: {
    favourites: [],
    addFavourite: null,
  },
  reducers: {
    setFavourites(state, payload) {
      return {
        ...state,
        favourites: payload,
      };
    },
    setAddFavourite(state, payload) {
      return {
        ...state,
        addFavourite: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async fetchFavourites(payload) {
      try {
        const data = Api.post("/?action=favourite_list", payload)
          .then(async function (response) {
            // Parse  JSON object individually
            const res = response.data;
            if (res.status !== 0) {
              await dispatch.favourite.setFavourites(res.data);
            } else {
              await dispatch.favourite.setFavourites([]);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
    async fetchAddFavourite(payload) {
      try {
        const data = Api.post("/?action=add_favourite_item", payload)
          .then(async function (response) {
            // Parse  JSON object individually
            const res = response.data;
            await dispatch.favourite.setAddFavourite(res.message);
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
  }),
};

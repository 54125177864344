import React, { useEffect } from "react";
import { useState } from "react";

import { Theme } from "../../components/UI/Theme";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ChirzModal from "../../components/UI/ChirzModal";
import ChirzSlider from "../../components/UI/ChirzSlider";
import ChirzLoader from "../../components/UI/ChirzLoader";
import MatchingWine from "../restaurantMenu/matchingDrinks/MatchingWine";
import MatchingCocktail from "../restaurantMenu/matchingDrinks/MatchingCocktail";

import noFoodDetailsCover from "../../assets/images/fooddetailscover.png";
import BackIcon from "../../assets/images/WhiteBackIcon.svg";
import foodNoCover from "../../assets/images/FoodNoCover.png";
import MatchingColdBrew from "../restaurantMenu/matchingDrinks/MatchingColdBrew";

const Food = (props) => {
  // State for matching drinks slider
  const [drinkType, setDrinkType] = useState("wine");

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Setting situation for closeHandler Func on RestaurantFoodItem.
  const closeHandler = () => {
    props.close(false);
  };
  // Change type of drinks slider.
  const drinkTypeHandler = (event, value) => {
    setDrinkType(value);
  };

  const isEmSherif = props.isEmSherif;

  useEffect(() => {
    if (props.matchingWines?.length > 0) {
      setDrinkType("wine");
    } else if (props.matchingCocktails?.length > 0) {
      setDrinkType("cocktails");
    } else {
      setDrinkType("coldbrew");
    }
  }, [props.matchingWines, props.matchingCocktails, props.matchingColdBrew]);
  

  return (
    <ChirzModal open={props.open} onClose={closeHandler} innerWidth="100vw" isFoodModal>
      <Box
        width="100%"
        pb={props.matchingWines?.length > 0 || props.matchingCocktails?.length > 0 || props.matchingColdBrew?.length > 0 ? "48px" : "0"}
        sx={{
          // height: { xs: "100vh", sm: "auto" },
          borderRadius: { xs: "0px", sm: "16px" },
          overflow: "hidden",
          backgroundColor: Theme.palette.offWhite,
          overflowY: "auto",
          height : isMobile ? "100vh" : "80vh"
        }}
      >
        <Box width="100%" height={170} sx={{ position: "relative" }}>
          <AppBar position="absolute">
            <Toolbar
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 0,
                paddingRight: 0,
              }}
              width="100%"
            >
              <Container
                width="100%"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <IconButton onClick={closeHandler} aria-label="previous">
                  <img src={BackIcon} width={12} height={12} alt="previous" />
                </IconButton>
              </Container>
            </Toolbar>
          </AppBar>
          <img
            src={props.img.replace("http://", "https://")}
            className="object-cover"
            width="100%"
            height="100%"
            alt="Food Cover"
            onError={(e) => {
              e.target.src = noFoodDetailsCover;
            }}
          />
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="8px"
          p="14px"
        >
          {props.name && (
            <Typography
              variant="foodTitle"
              textAlign="center"
              fontFamily={isEmSherif ? "Sackers Gothic Std" : undefined}
            >
              {props.name}
            </Typography>
          )}
          {props.desc && (
            <Typography
              maxWidth={327}
              variant="regular"
              textAlign="center"
              fontFamily={isEmSherif ? "Montserrat" : undefined}
            >
              {props.desc}
            </Typography>
          )}
          {props.price && (
            <Typography
              variant="large"
              fontWeight={700}
              fontFamily={isEmSherif ? "Montserrat" : undefined}
            >
              {props.price}
            </Typography>
          )}
          <Tabs
            aria-label="drinks-menu"
            value={drinkType}
            indicatorColor="transparent"
            variant="scrollable"
            scrollButtons="auto"
            onChange={drinkTypeHandler}
            sx={{ mt: "8px" }}
          >
            {props.matchingWines?.length > 0 && (
              <Tab
                label="Wine"
                key="wine"
                value="wine"
                sx={{
                  color: isEmSherif ? "#183861 !important" : "",
                  backgroundColor: isEmSherif ? "#97bbe3 !important" : "",
                  "&.Mui-selected": {
                    color: isEmSherif ? "#ffffff !important" : "",
                    backgroundColor: isEmSherif ? "#354b77 !important" : "",
                  },
                  fontFamily: isEmSherif ? "Montserrat !important" : undefined,
                }}
              />
            )}
            {props.matchingCocktails?.length > 0 && (
              <Tab
                label="Cocktails"
                key="cocktails"
                value="cocktails"
                sx={{
                  color: isEmSherif ? "#183861 !important" : "",
                  backgroundColor: isEmSherif ? "#97bbe3 !important" : "",
                  "&.Mui-selected": {
                    color: isEmSherif ? "#ffffff !important" : "",
                    backgroundColor: isEmSherif ? "#354b77 !important" : "",
                  },
                  fontFamily: isEmSherif ? "Montserrat !important" : undefined,
                }}
              />
            )}
            {props.matchingColdBrew?.length > 0 && (
              <Tab
                label="Cold Brew"
                key="coldbrew"
                value="coldbrew"
                sx={{
                  color: isEmSherif ? "#183861 !important" : "",
                  backgroundColor: isEmSherif ? "#97bbe3 !important" : "",
                  "&.Mui-selected": {
                    color: isEmSherif ? "#ffffff !important" : "",
                    backgroundColor: isEmSherif ? "#354b77 !important" : "",
                  },
                  fontFamily: isEmSherif ? "Montserrat !important" : undefined,
                }}
              />
            )}
          </Tabs>
        </Box>
        {props.matchingWines === undefined &&
          props.matchingCocktails === undefined &&
          props.matchingColdBrew === undefined && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <ChirzLoader />
            </Box>
          )}
        {props.matchingWines !== undefined && (
          <Box role="tabpanel" hidden={drinkType !== "wine"}>
            {props.matchingWines.length !== 0 && (
              <ChirzSlider>
                {props.matchingWines.map((drink, index) => {
                  if (drink.is_cocktail === "0") {
                    return (
                      <MatchingWine
                        key={index}
                        id={drink.id}
                        name={drink.item_name}
                        year={drink.year}
                        pricePerGlass={drink.price_per_glass}
                        pricePerBottle={drink.price_per_bott}
                        isEmSherif={isEmSherif}
                      />
                    );
                  }
                })}
              </ChirzSlider>
            )}
          </Box>
        )}
        {props.matchingCocktails !== undefined && (
          <Box role="tabpanel" hidden={drinkType !== "cocktails"}>
            {props.matchingCocktails.length !== 0 && (
              <ChirzSlider>
                {props.matchingCocktails.map((drink, index) => {
                  if (drink.is_cocktail !== "0") {
                    return (
                      <MatchingCocktail
                        key={index}
                        id={drink.id}
                        name={drink.item_name}
                        pricePerGlass={drink.price_per_cocktail}
                        isEmSherif={isEmSherif}
                      />
                    );
                  }
                })}
              </ChirzSlider>
            )}
          </Box>
        )}

        {props.matchingColdBrew !== undefined && (
          <Box role="tabpanel" hidden={drinkType !== "coldbrew"}>
            {props.matchingColdBrew.length !== 0 && (
              <ChirzSlider>
                {props.matchingColdBrew.map((drink, index) => {
                  return (
                    <MatchingColdBrew
                      key={index}
                      id={drink.id}
                      name={drink.item_name}
                      pricePerGlass={drink.price_per_cocktail}
                      isEmSherif={isEmSherif}
                    />
                  );
                })}
              </ChirzSlider>
            )}
          </Box>
        )}
      </Box>
    </ChirzModal>
  );
};

export default Food;

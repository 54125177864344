import React, { useEffect, useState } from "react";
import { Autocomplete, Grid,Popper,styled,TextField } from "@mui/material";
import { useSelector } from "react-redux";

const CustomPopper = styled(Popper)((props) => ({
  '& .MuiAutocomplete-listbox': {
    fontFamily: props.isEmSherif ? "Montserrat" : undefined,
  },
}));


const Filters = ({ filterValue, setFilterValue, isEmSherif }) => {
  const foodList = useSelector((state) => state.food.foodList);
  const [filterData, setFilterData] = useState({
    typeData : [],
    regionData : [],
    grapeData : [],
    alcoholData : [],
    yearData : [],
  });
  

  useEffect(() => {
    const getUniqueValues = (key) => {
      const wineItems = foodList?.filter((item) => item.menu_name === 'Wine') || [];
      const values = wineItems.flatMap((item) => item?.food.map((food) => food[key]));
      return Array.from(new Set(values));
    };

    setFilterData({
      typeData: getUniqueValues('item_type'),
      regionData: getUniqueValues('region'),
      grapeData: getUniqueValues('type_of_grape'),
      alcoholData: getUniqueValues('alcohol_percentage'),
      yearData: getUniqueValues('year'),
    });
    
  }, [foodList]);

  const handleChange = (key) => (_, newValue) => {
    setFilterValue((prev) => {
      const newFilters = { ...prev, [key]: newValue };
      return newFilters;
    });
  };

  const renderAutocomplete = (label, key) => (
    <Grid item xs={6} sm={6} key={key}>
      <Autocomplete
        value={filterValue[key]}
        onChange={handleChange(key)}
        options={filterData[`${key}Data`]}
        renderInput={(params) => <TextField {...params} label={label} sx={{
          '& .MuiInputBase-input': {
            fontFamily: isEmSherif ? "Montserrat" : undefined,
          },
        }}/>}
        size="small"
        componentsProps={{ paper: { style : { width : "max-content" }} }}
        PopperComponent={(props) => <CustomPopper {...props} isEmSherif={isEmSherif}/>}
      />
    </Grid>
  );

  return (
    <Grid container spacing={1}>
      {renderAutocomplete('Type', 'type')}
      {renderAutocomplete('Region', 'region')}
      {renderAutocomplete('Grape', 'grape')}
      {renderAutocomplete('Alcohol', 'alcohol')}
      {renderAutocomplete('Year', 'year')}
    </Grid>
  )
};

export default Filters;

import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ChirzInput } from "../../../../components/UI/ChirzInput";

const PasswordInfo = (props) => {
  const [info, setInfo] = useState(props.details);
  const infoRef = useRef({});

  const changeHandler = (e) => {
    infoRef.current = { [e.target.name]: e.target.value };
    setInfo((prevInfo) => ({ ...prevInfo, ...infoRef.current }));
    props.onChange(infoRef.current);
  };

  useEffect(() => {
    if (info.password !== "" && info.confirmPassword !== "" && info.password === info.confirmPassword) {
      props.onConfirm(false);
    } else{
      props.onConfirm(true);
    }
  }, [info]);

  return (
    <Box display="flex" flexDirection="column" gap="16px" pt="32px">
      <Typography variant="largeNone" fontWeight={700} pb="8px">
        Add a password.
      </Typography>
      <ChirzInput
        name="password"
        label="Password"
        value={info.password}
        variant="outlined"
        type="password"
        onChange={changeHandler}
      />
      <ChirzInput
        name="confirmPassword"
        label="Confirm password"
        value={info.confirmPassword}
        variant="outlined"
        type="password"
        onChange={changeHandler}
      />
    </Box>
  );
};

export default PasswordInfo;

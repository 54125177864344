import React from "react";

import Sidebar from "./sidebar/Sidebar";

const Dashboard = (props) => {
  const sidebarHandler = (situation) => {
    props.onSidebar(situation);
  };

  return (
    <Sidebar
      sidebar={props.opened}
      onSidebarNav={sidebarHandler}
      user={props.user}
    />
  );
};
export default Dashboard;

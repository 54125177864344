import React from "react";

import { Toolbar, IconButton, Typography } from "@mui/material";

import BackIcon from "../../../../assets/images/BackIcon.svg";

const RegisterNav = (props) => {
  const dashboardNavStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };
  return (
    <Toolbar sx={dashboardNavStyle}>
      <IconButton onClick={props.onClick} aria-label="pervious">
        <img src={BackIcon} width={12} height={12} alt="previous" />
      </IconButton>
      <Typography
        variant="large"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Register
      </Typography>
    </Toolbar>
  );
};
export default RegisterNav;

import React, { useEffect, useMemo, useState } from "react";
import store from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { Theme } from "../../components/UI/Theme";
import {
  Alert,
  AppBar,
  Box,
  Container,
  IconButton,
  Snackbar,
  Toolbar,
  Typography,
} from "@mui/material";
import ChirzComment from "../../components/UI/ChirzComment";
import ChirzLoader from "../../components/UI/ChirzLoader";

import "./Drink.css";
import BackIcon from "../../assets/images/BackIcon.svg";
import ChirzModal from "../../components/UI/ChirzModal";
import { ChirzBtn, LightBtn } from "../../components/UI/ChirzButtons";

const Drink = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { state } = useLocation();

  const isColdBrew = useMemo(() => {
    if (state) {
      return state.isColdBrew;
    }
  }, [state]);

  // State for showing login modal.
  const [modal, setModal] = useState(false);
  // State for showing or hiding alert.
  const [alert, setAlert] = useState(false);
  // State for error alert details.
  const [alertDetails, setAlertDetails] = useState({
    color: "error",
    message: "",
  });
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.users.user);
  const bookmarksItem = useSelector((state) => state.favourite.favourites);
  const [drinkComments, setDrinkComments] = useState(undefined);
  const [drinkDetails, setDrinkDetails] = useState(undefined);
  let conditioanState;
  const bookmarkedInitialstate = bookmarksItem.find(
    (bookmark) => bookmark.item_id === id
  );
  if (bookmarkedInitialstate) {
    conditioanState = true;
  } else {
    conditioanState = false;
  }
  const [bookmarked, setBookmarked] = useState(conditioanState);

  // Create function for getting drink details
  const drinkDetailsHandler = async () => {
    // Call fetchDrinkDetails function from drink modal.
    const drinkDetails = await dispatch.drink.fetchDrinkDetails({
      item_id: id,
    });
    let currentDrinkDetails = store.getState().drink.drinkDetails;
    setDrinkDetails(currentDrinkDetails);
    return drinkDetails;
  };
  // Create function for getting rating comments
  const ratingHandler = async () => {
    // Call fetchComments function from rating modal.
    const commentsList = await dispatch.rating.fetchComments({
      item_id: id,
    });
    let currentDrinkComments = store.getState().rating.comments;
    setDrinkComments(currentDrinkComments);
    return commentsList;
  };
  // Function for adding to or removing from favourite list.
  const bookmarkedHandler = async () => {
    if (loggedInUser !== undefined) {
      let currentFavourite = new FormData();
      currentFavourite.append("user_id", loggedInUser.u_id);
      currentFavourite.append("item_id", id);
      await dispatch.favourite.fetchAddFavourite(currentFavourite);
      let bookmarkMessage = store.getState().favourite.addFavourite;
      setAlert(true);
      if (!bookmarked) {
        setAlertDetails({
          color: "success",
          message: bookmarkMessage,
        });
      } else {
        setAlertDetails({
          color: "error",
          message: bookmarkMessage,
        });
      }
      setBookmarked(!bookmarked);
      await dispatch.favourite.fetchFavourites(currentFavourite);
    } else {
      setModal(true);
    }
  };
  // Create show or hide alert function
  const alertHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };
  const modalHandler = () => {
    setModal(false);
  };
  useEffect(() => {
    drinkDetailsHandler();
    ratingHandler();
  }, []);
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        width="100%"
        sx={{
          overflow: "hidden",
          backgroundColor: Theme.palette.offWhite,
          overflowY: "auto",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="8px"
          width="100%"
          p="16px"
          pt="48px"
          backgroundColor={Theme.palette.white}
          sx={{ position: "relative" }}
        >
          <AppBar position="absolute">
            <Toolbar
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 0,
                paddingRight: 0,
              }}
              width="100%"
            >
              <Container
                width="100%"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => {
                    navigate(-1);
                  }}
                  aria-label="previous"
                >
                  <img src={BackIcon} width={12} height={12} alt="previous" />
                </IconButton>
                <Box>
                  <IconButton
                    aria-label="bookmark"
                    onClick={bookmarkedHandler}
                    className={bookmarked ? "bookmarked" : ""}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.8215 15.506C11.9905 15.506 12.1605 15.549 12.3135 15.635L17.0005 18.286V5.334C17.0005 5.133 16.8795 5 16.8005 5H7.20049C7.12049 5 7.00049 5.133 7.00049 5.334V18.234L11.3065 15.649C11.4655 15.554 11.6435 15.506 11.8215 15.506ZM6.00049 21C5.83049 21 5.66049 20.957 5.50749 20.87C5.19449 20.693 5.00049 20.36 5.00049 20V5.334C5.00049 4.047 5.98749 3 7.20049 3H16.8005C18.0135 3 19.0005 4.047 19.0005 5.334V20C19.0005 20.356 18.8115 20.685 18.5045 20.864C18.1965 21.044 17.8185 21.047 17.5085 20.871L11.8365 17.663L6.51449 20.858C6.35649 20.952 6.17849 21 6.00049 21Z"
                        fill="#090A0A"
                      />
                    </svg>
                  </IconButton>
                </Box>
              </Container>
            </Toolbar>
          </AppBar>
          {drinkDetails !== undefined && drinkDetails.item_name && (
            <Typography
              variant="title"
              lineHeight="36px"
              color="#090A0A"
              textAlign="center"
            >
              {drinkDetails.item_name}
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          gap="32px"
          p="20px"
          borderTop="1px solid #F2F4F5"
          borderBottom="1px solid #F2F4F5"
        >
          {drinkDetails !== undefined && drinkDetails.about_wine && (
            <Typography
              maxWidth="90%"
              variant="regularTight"
              textAlign="center"
            >
              {drinkDetails.about_wine}
            </Typography>
          )}
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="8px"
          >
            {drinkDetails !== undefined &&
              drinkDetails.is_cocktail === "0" &&
              drinkDetails.item_type && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  gap="20px"
                >
                  <Typography
                    variant="regular"
                    fontWeight={700}
                    sx={{ width: "100px", textAlign: "right" }}
                  >
                    Type
                  </Typography>
                  <Typography variant="regular" sx={{ width: "120px" }}>
                    {drinkDetails.item_type}
                  </Typography>
                </Box>
              )}
            {drinkDetails !== undefined &&
              drinkDetails.is_cocktail === "0" &&
              drinkDetails.region && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  gap="20px"
                >
                  <Typography
                    variant="regular"
                    fontWeight={700}
                    sx={{ width: "100px", textAlign: "right" }}
                  >
                    Region
                  </Typography>
                  <Typography variant="regular" sx={{ width: "120px" }}>
                    {drinkDetails.region}
                  </Typography>
                </Box>
              )}
            {drinkDetails !== undefined &&
              drinkDetails.is_cocktail === "0" &&
              drinkDetails.type_of_grape && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  gap="20px"
                >
                  <Typography
                    variant="regular"
                    fontWeight={700}
                    sx={{ width: "100px", textAlign: "right" }}
                  >
                    Grape
                  </Typography>
                  <Typography variant="regular" sx={{ width: "120px" }}>
                    {drinkDetails.type_of_grape}
                  </Typography>
                </Box>
              )}
            {drinkDetails !== undefined &&
              drinkDetails.alcohol_percentage &&
              !isColdBrew && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  gap="20px"
                >
                  <Typography
                    variant="regular"
                    fontWeight={700}
                    sx={{
                      width: "100px",
                      textAlign: "right",
                      position: "relative",
                    }}
                  >
                    Alcohol
                  </Typography>
                  <Typography variant="regular" sx={{ width: "120px" }}>
                    {drinkDetails.alcohol_percentage}%
                  </Typography>
                </Box>
              )}
            {drinkDetails !== undefined &&
              drinkDetails.is_cocktail === "0" &&
              drinkDetails.year && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  gap="20px"
                >
                  <Typography
                    variant="regular"
                    fontWeight={700}
                    sx={{
                      width: "100px",
                      textAlign: "right",
                      position: "relative",
                    }}
                  >
                    Year
                  </Typography>
                  <Typography variant="regular" sx={{ width: "120px" }}>
                    {drinkDetails.year}
                  </Typography>
                </Box>
              )}
          </Box>
          <Box width="100%">
            {drinkComments === undefined && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                <ChirzLoader />
              </Box>
            )}
            {drinkComments !== undefined && (
              <ChirzComment id={id} data={drinkComments} />
            )}
          </Box>
        </Box>
      </Box>
      <ChirzModal open={modal} onClose={modalHandler} innerWidth="100vw">
        <Container padding="20px">
          {!loggedInUser && (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              gap="16px"
              justifyContent="center"
              alignItems="center"
              padding="16px"
              borderRadius="16px"
              sx={{ backgroundColor: Theme.palette.white }}
            >
              <Typography
                variant="foodTitle"
                textAlign="center"
                textTransform="uppercase"
              >
                Login or register
              </Typography>
              <Typography variant="regular" textAlign="center" width="90%">
                You will be able to save your bookmark wines and leave comments.
              </Typography>
              <ChirzBtn
                LinkComponent={Link}
                to="/login"
                sx={{
                  width: "100%",
                  height: "48px",
                  mt: "16px",
                  textTransform: "uppercase",
                }}
                variant="chirz"
              >
                Log in
              </ChirzBtn>
              <LightBtn
                LinkComponent={Link}
                to="/register"
                sx={{
                  width: "100%",
                  height: "48px",
                  textTransform: "uppercase",
                }}
              >
                Register
              </LightBtn>
            </Box>
          )}
        </Container>
      </ChirzModal>
      <Snackbar
        open={alert}
        autoHideDuration={1500}
        onClose={alertHandler}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={alertHandler}
          severity={alertDetails.color}
          sx={{ width: "100%" }}
        >
          {alertDetails.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Drink;

import styled from "@emotion/styled";
import { Input, TextField } from "@mui/material";
import { Theme } from "./Theme";

export const ChirzInput = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: Theme.palette.chirz.red,
    },
  },
  "& .MuiFormLabel-root": {
    lineHeight: "16px",
    color: "#72777A",
  },
  "& .MuiInputBase-root": {
    borderRadius: 8,
    height: 48,
    "& input": {
      padding: 0,
      height: 48,
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    "& fieldset": {
      borderColor: Theme.palette.sky,
    },
  },
});

export const ChirzNumberInput = styled(ChirzInput)({
  width: 48,
  height: 48,
  "& .MuiInputBase-root": {
    borderRadius: "100%",
  },
  "& input": {
    width: 48,
    height: 48,
    textAlign: "center",
  },
});
export const ChirzReadOnly = styled(Input)({
  "& input": {
    textAlign: "right",
  },
  "::before": {
    display: "none",
  },
  "::after": {
    display: "none",
  },
});

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Theme } from "../../components/UI/Theme";
import { Box, IconButton, Typography } from "@mui/material";

import Star from "../../assets/images/Star.svg";
import { useNavigate } from "react-router-dom";

const FavouriteItem = (props) => {
  const navigation = useNavigate();
  const showFavouriteHandler = () => {
    navigation(`/drink/${props.id}`);
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      height={40}
      onClick={showFavouriteHandler}
    >
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography
          variant="regularTight"
          fontWeight={700}
          color={Theme.palette.almostBlack}
        >
          {props.title}
        </Typography>
        <Box display="flex" gap="8px">
          {props.subTitle !== "NA" && (
            <Typography variant="smallTight" color="#6C7072">
              {props.subTitle}
            </Typography>
          )}
          {props.year !== "0" && (
            <Typography variant="smallTight" color="#6C7072">
              {props.year}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FavouriteItem;

import Api from "../../lib/api";

export const food = {
  state: {
    foodList: [],
    matchingWines: [],
    matchingCocktails: [],
    matchingColdBrew : [],
  },
  reducers: {
    setFoodList(state, payload) {
      return {
        ...state,
        foodList: payload,
      };
    },
    setMatchingWines(state, payload) {
      return {
        ...state,
        matchingWines: payload,
      };
    },
    setMatchingCocktails(state, payload) {
      return {
        ...state,
        matchingCocktails: payload,
      };
    },
    setMatchingColdBrew(state, payload) {
      return {
        ...state,
        matchingColdBrew: payload,
      };
    },
    setFoodDetails(state, payload) {
      return {
        ...state,
        foodDetails: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async fetchFoodList(payload) {
      try {
        const data = Api.post("/?action=wine_pairing", payload)
          .then(async function (response) {
            const res = response.data;
            if (res.status !== 0) {
              await dispatch.food.setFoodList(res.data);
            } else {
              await dispatch.food.setFoodList([]);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
    async fetchMatchingWines(payload) {
      try {
        const data = Api.post("/?action=food_base_wine", payload)
          .then(async function (response) {
            const res = response.data;
            if (res.status !== 0) {
              await dispatch.food.setMatchingWines(res.data);
            } else {
              await dispatch.food.setMatchingWines([]);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
    async fetchMatchingCocktails(payload) {
      try {
        const data = Api.post("/?action=food_base_wine", payload)
          .then(async function (response) {
            //const res = await mapper(response.data);
            const res = response.data;
            if (res.status !== 0) {
              await dispatch.food.setMatchingCocktails(res.data);
            } else {
              await dispatch.food.setMatchingCocktails([]);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
    async fetchMatchingColdBrew(payload) {
      try {
        const data = Api.post("/?action=food_base_wine", payload)
          .then(async function (response) {
            //const res = await mapper(response.data);
            const res = response.data;
            if (res.status !== 0) {
              await dispatch.food.setMatchingColdBrew(res.data);
            } else {
              await dispatch.food.setMatchingColdBrew([]);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
  }),
};
const mapper = async (data) => {
  const responseArray = data.split("\n");
  const res = await JSON.parse(responseArray[1]);
  return res;
};

import React from "react";
import { Box, Typography } from "@mui/material";
import { ChirzBtn } from "../../components/UI/ChirzButtons";
import { Link } from "react-router-dom";

const NoItemsFound = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "12px",
      }}
    >
      <Typography align="center" variant="large" fontWeight={700}>
        {props.title}
      </Typography>
      <Typography align="center" variant="small">
        {props.children}
      </Typography>
      <ChirzBtn LinkComponent={Link} to="/" sx={{ width: 174, mt: "12px" }}>Explore</ChirzBtn>
    </Box>
  );
};

export default NoItemsFound;

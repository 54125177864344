import React from "react";
import { Toolbar, IconButton, Typography} from "@mui/material";

import BackIcon from "../../assets/images/BackIcon.svg";
import { Link } from "react-router-dom";

const DashboardNav = (props) => {
  let dashboardNavStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };
  if (props.border) {
    dashboardNavStyle = Object.assign(
      { borderBottom: "1px solid #E3E5E5" },
      dashboardNavStyle
    );
  }
  return (
    <Toolbar sx={dashboardNavStyle}>
      <IconButton LinkComponent={Link} to="/" aria-label="pervious">
        <img src={BackIcon} width={12} height={12} alt="previous" />
      </IconButton>
      <Typography
        variant="large"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.title}
      </Typography>
    </Toolbar>
  );
};

export default DashboardNav;

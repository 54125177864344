import Api from "../../lib/api";
export const rating = {
  state: {
    comments: [],
    addComment: null,
    error: null,
  },
  reducers: {
    setComments(state, payload) {
      return {
        ...state,
        comments: payload,
      };
    },
    setAddComment(state, payload) {
      return {
        ...state,
        addComment: payload,
      };
    },
    setAddError(state, payload) {
      return {
        ...state,
        error: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async fetchComments(payload) {
      try {
        const data = Api.post("/?action=rating", payload)
          .then(async function (response) {
            // Parse  JSON object individually
            //const res = await mapper(response.data);
            const res = response.data;
            if (res.status !== 0) {
              await dispatch.rating.setComments(res.data);
            } else {
              await dispatch.rating.setComments([]);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
    async fetchAddComment(payload) {
      try {
        const data = Api.post("/?action=add_rating", payload)
          .then(async function (response) {
            // Parse  JSON object individually
            //const res = await mapper(response.data);
            const res = response.data;
            if (res.status !== 0) {
              await dispatch.rating.setAddComment(res.data);
              await dispatch.rating.setAddError(null);
            } else {
              await dispatch.rating.setAddError(res.response);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
  }),
};

const mapper = async (data) => {
  const responseArray = data.split("\n");
  const res = await JSON.parse(responseArray[1]);
  return res;
};

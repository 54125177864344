import { Box, Typography } from "@mui/material";
import React from "react";

const OutOfStock = () => {
  return (
    <Box
      position="absolute"
      right={8}
      top={-16}
    >
      <Typography variant="tinyBadge" fontWeight={700} sx={{ zIndex: 10 }}>
        out of stock
      </Typography>
    </Box>
  );
};

export default OutOfStock;
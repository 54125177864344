import styled from "@emotion/styled";
import { LinearProgress, linearProgressClasses } from "@mui/material";
import { Theme } from "./Theme";

export const ChirzLinearProgress = styled(LinearProgress)({
  borderRadius: 100,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: Theme.palette.sky,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 100,
  },
});

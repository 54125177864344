import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "../../redux/store";
import { Link } from "react-router-dom";

import { Box, Container, Typography } from "@mui/material";
import ChirzLoader from "../../components/UI/ChirzLoader";
import ChirzCarousel from "../../components/UI/ChirzCarousel";
import Navbar from "./navbar/Navbar";
import Search from "../../components/Search/Search";
import "../../components/UI/styles/ChirzLink.css";
import NoImage from "../../assets/images/NoImg.png";

const Home = () => {
  // Define Basics.
  const dispatch = useDispatch();
  const [nearRestaurants, setNearRestaurants] = useState(undefined);
  const [suggestedRestaurants, setSuggestedRestaurants] = useState(undefined);
  const [allRestaurants, setAllRestaurants] = useState([]);
  const [allRestaurantsName, setAllRestaurantsName] = useState([]);
  // State for set location.
  let userLocation = useSelector((state) => state.users.userLocation);
  const [location, setLocation] = useState(userLocation);
  const [searchResult, setSearchResult] = useState(undefined);
  // Create function for getting restaurants list based on location
  const nearRestaurantsHandler = async () => {
    // Call fetchNearRestaurants function from restaurants modal.
    await dispatch.users.fetchUserLocation(location);
    const nearRestaurantsArr = await dispatch.restaurants.fetchNearRestaurants(
      location
    );
    let restaurants = store.getState().restaurants.nearRestaurants;
    setNearRestaurants(restaurants);
    return nearRestaurantsArr;
  };
  // Create function for getting suggested restaurants list
  const suggestedRestaurantsHandler = async () => {
    // Call fetchSuggestedRestaurants function from restaurants modal.
    const suggestedRestaurantsArr =
      await dispatch.restaurants.fetchSuggestedRestaurants();
    let suggestedRestaurants =
      store.getState().restaurants.suggestedRestaurants;
    let searchRestaurants = suggestedRestaurants.map(
      (restaurant) => restaurant.Restaurantname
    );
    setSuggestedRestaurants(suggestedRestaurants);
    setAllRestaurants(suggestedRestaurants);
    setAllRestaurantsName(searchRestaurants);
    return suggestedRestaurantsArr;
  };
  const searchHandler = (item) => {
    if (item !== null && item !== "") {
      const searchDetails = allRestaurants.find(
        (restaurant) => restaurant.Restaurantname === item
      );
      if (searchDetails !== undefined) {
        setSearchResult(searchDetails);
      }
    } else {
      setSearchResult(undefined);
    }
  };
  // Change location based on user location
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      setLocation({
        latitude: "51.5072",
        longitude: "0.1276",
      });
    }
    nearRestaurantsHandler();
    suggestedRestaurantsHandler();
  }, []);
  return (
    <>
      <Navbar />
      <Container sx={{ borderBottom: "1px solid #F2F4F5",}}>
        <Search item={allRestaurantsName} searchKeyHandler={searchHandler} />
      </Container>
      {searchResult === undefined && (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            my: "24px",
            paddingRight: "0px",
          }}
        >
          <Box display="flex" flexDirection="column" gap="20px" pb="32px">
            <Typography variant="largeNone" fontWeight={700}>
              Nearby
            </Typography>
            {nearRestaurants === undefined && (
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ChirzLoader />
              </Box>
            )}
            {nearRestaurants !== undefined && (
              <ChirzCarousel>
                {nearRestaurants.map((Restaurant, index) => (
                  <Box key={index} mx="10px">
                    <Link
                      to={`/restaurant/${Restaurant.Restaurantname.toLocaleLowerCase()
                        .split(" ")
                        .join("")}/${Restaurant.Restaurant_id}`}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap="8px"
                      >
                        {!Restaurant.Image && (
                          <img
                            src={NoImage}
                            width={141}
                            height={141}
                            style={{
                              borderRadius: "16px",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        )}
                        {Restaurant.Image && (
                          <img
                            src={Restaurant.Image.replace(
                              "http://",
                              "https://"
                            )}
                            width={141}
                            height={141}
                            style={{
                              borderRadius: "16px",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        )}
                        {Restaurant.Restaurantname && (
                          <Typography
                            textAlign="center"
                            variant="regularNone"
                            fontWeight={700}
                          >
                            {Restaurant.Restaurantname}
                          </Typography>
                        )}
                      </Box>
                    </Link>
                  </Box>
                ))}
              </ChirzCarousel>
            )}
          </Box>
          <Box display="flex" flexDirection="column" gap="20px" pb="32px">
            <Typography variant="largeNone" fontWeight={700}>
              Suggested
            </Typography>
            {suggestedRestaurants === undefined && (
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ChirzLoader />
              </Box>
            )}
            {suggestedRestaurants !== undefined && (
              <ChirzCarousel>
                {suggestedRestaurants.map((Restaurant, index) => (
                  <Box key={index} mx="10px">
                    <Link
                      to={`/restaurant/${Restaurant.Restaurantname.toLocaleLowerCase()
                        .split(" ")
                        .join("")}/${Restaurant.Restaurant_id}`}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap="8px"
                      >
                        {!Restaurant.Image && (
                          <img
                            src={NoImage}
                            width={141}
                            height={141}
                            style={{
                              borderRadius: "16px",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        )}
                        {Restaurant.Image && (
                          <img
                            src={Restaurant.Image.replace(
                              "http://",
                              "https://"
                            )}
                            width={141}
                            height={141}
                            style={{
                              borderRadius: "16px",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        )}
                        {Restaurant.Restaurantname && (
                          <Typography
                            textAlign="center"
                            variant="regularNone"
                            fontWeight={700}
                          >
                            {Restaurant.Restaurantname}
                          </Typography>
                        )}
                      </Box>
                    </Link>
                  </Box>
                ))}
              </ChirzCarousel>
            )}
          </Box>
        </Container>
      )}
      {searchResult !== undefined && (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            my: "24px",
            paddingRight: "0px",
          }}
        >
          <Box display="flex" flexDirection="column" gap="20px" pb="32px">
            <ChirzCarousel>
              <Box mx="10px">
                <Link
                  to={`/restaurant/${searchResult.Restaurantname.toLocaleLowerCase()
                    .split(" ")
                    .join("")}/${searchResult.Restaurant_id}`}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap="8px"
                  >
                    {!searchResult.Image && (
                      <img
                        src={NoImage}
                        width={141}
                        height={141}
                        style={{
                          borderRadius: "16px",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                      />
                    )}
                    {searchResult.Image && (
                      <img
                        src={searchResult.Image.replace("http://", "https://")}
                        width={141}
                        height={141}
                        style={{
                          borderRadius: "16px",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                      />
                    )}
                    {searchResult.Restaurantname && (
                      <Typography
                        textAlign="center"
                        variant="regularNone"
                        fontWeight={700}
                      >
                        {searchResult.Restaurantname}
                      </Typography>
                    )}
                  </Box>
                </Link>
              </Box>
            </ChirzCarousel>
          </Box>
        </Container>
      )}
    </>
  );
};
export default Home;

import styled from "@emotion/styled";
import { Link } from "@mui/material";

export const ChirzLink = styled(Link)({
  "a:-webkit-any-link": {
    color: "#990012",
    textDecoration: "none",
  },
  span: {
    textDecoration: "none",
  },
});

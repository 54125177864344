import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const PhoneInfo = (props) => {
  const [info, setInfo] = useState(props.details);
  const infoRef = useRef({});

  const changeHandler = (e) => {
    infoRef.current = { phone: e };
    setInfo((prevInfo) => ({ ...prevInfo, ...infoRef.current }));
    props.onChange(infoRef.current);
  };

  useEffect(() => {
    if (info.phone !== "") {
      props.onConfirm(false);
    } else {
      props.onConfirm(true);
    }
  }, [info]);

  return (
    <Box display="flex" flexDirection="column" gap="16px" pt="32px">
      <Typography variant="largeNone" fontWeight={700} pb="8px">
        We need to make sure you’re real.
      </Typography>
      <PhoneInput
        name="phone"
        international
        placeholder="Mobile number"
        value={info.phone}
        onChange={changeHandler}
        defaultCountry={"GB"}
      />
    </Box>
  );
};

export default PhoneInfo;

import Api from "../../lib/api";

export const drink = {
  state: {
    drinkDetails: [],
  },
  reducers: {
    setDrinkDetails(state, payload) {
      return {
        ...state,
        drinkDetails: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async fetchDrinkDetails(payload) {
      try {
        const data = Api.post("/?action=item_detail", payload)
          .then(async function (response) {
            // Parse  JSON object individually
            const res = response.data;
            if (res.status !== 0) {
              await dispatch.drink.setDrinkDetails(res.data);
            } else {
              await dispatch.drink.setDrinkDetails([]);
            }
          })
          .catch(function (error) {});
        return data;
      } catch (error) {}
    },
  }),
};
